@font-face {
    font-family: "Hylia";
    src: url(../fonts/LTPerfume-2.ttf);
}

* {    overflow-x: hidden;
}
.container {
    display: flex;
    position: relative;
}
.chai, 
.vignes {
    height: 100vh;

}
.chai {
    background-image: url(../images/chai01.jpg);
    background-size: cover;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.vignes {
    background-image: url(../images/vignes.jpg);
    background-size: cover;
    background-position: left;
    width: 70%;
   
}
.vignes h1 {
    font-family: 'Hylia';
    font-size: 90px;
    text-align: center;

}
.logo {
    width: 300px;
    height: 300px;
    position: absolute; 
    top: 30%; 
    left: 22%; 

}
.article {
    margin: auto;
    width: 78%;
    height: 75vh;
    background: rgba(255, 255, 255, 0.28);
    border-radius: 15px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(8.5px);
    border: 1px solid rgba(255, 255, 255, 0.01);
    margin-top: 100px;
    margin-left: 200px;
    padding: 1px 5px; 
    overflow-y: hidden;
}
.article p {
    font-family: 'Garamond';
    font-size: 35px;
    text-align: center;
    font-weight: bolder;
}
.presentation {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.a {
    text-decoration: none;
    color: black;
}
.a:hover {
    background-color: black;
    color: #da0035;
    border-radius: 5px;
    padding: 0px 4px;
}
.instagram {
    position: absolute;
    right: 1%;
    top: 93%;
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 10px;
}
.instagram a:hover {
    background-color: black;
}
.jg {
    position: absolute;
    right: 50%;
    top: 97%;
    width: 20px;
    height: 20px;

}
/* ################## MEDIA QUERIES POUR LE RESPONSIVE ######################### */
/* ##### TABLETTES #### */

@media screen and (min-width: 320px) and (max-width: 475px) {
    .chai {
        display: none;
    }
    .vignes {
        width: 100%;
    }
    .article {
        position: relative;
        margin: 150px auto 0;
        width: 80%; 
        height: 70vh;
        overflow-y: hidden;
    }    
    .logo {
        position: absolute;
        top: 15%; /* Ajustez cette valeur si nécessaire */
        transform: translate(-50%, -50%);
        left: 50%;
        width: 200px;
        height: 200px;
        z-index: 1;
    }
    .vignes h1 {
        font-size: 60px;
        margin-top: 80px;
        margin-bottom: 0;
        padding-right: 5px;
    }
    .article p {
        font-size: 22px;
        margin: 10px;
    }
    .presentation {
        margin-top: 0px;
    }
    h4 {
        font-size: 20px;
    }
    .instagram {
        top: 92%
    }
}
@media screen and (min-width: 476px) and (max-width: 1023px) {
    .chai {
        background-size: 100% 80% cover;
        background-position: right;
    }
    .article {
        position: relative;
        margin: 100px auto 0;
        width: 80%; 
        height: 54vh;
        overflow-y: hidden;
    }    
    .logo {
        position: absolute;
        top: 45%; /* Ajustez cette valeur si nécessaire */
        transform: translate(-50%, -50%);
        left: 30%;
        width: 200px;
        height: 200px;
        z-index: 1;
    }
    .vignes h1 {
        font-size: 60px;
        margin-top: 80px;
        margin-bottom: 0;
        padding-right: 5px;
    }
    .article p {
        font-size: 22px;
        margin: 10px;
    }
    .presentation {
        margin-top: 0px;
    }
    h4 {
        font-size: 20px;
    }
    .instagram {
        top: 90%
    }
}
@media screen and (min-width: 1024px) and (max-width: 1439px) {
    .chai {
        background-size: 100% 80% cover;
        background-position: right;
    }
    .article {
        position: relative;
        margin: 150px auto 0;
        width: 80%; 
        height: 54vh;
        overflow-y: hidden;
    }    
    .logo {
        position: absolute;
        top: 45%; 
        transform: translate(-50%, -50%);
        left: 30%;
        width: 200px;
        height: 200px;
        z-index: 1;
    }
    .vignes h1 {
        font-size: 60px;
        margin-top: 80px;
        margin-bottom: 0;
        padding-right: 5px;
    }
    .article p {
        font-size: 22px;
        margin: 10px;
    }
    .presentation {
        margin-top: 0px;
    }
    h4 {
        font-size: 20px;
    }
    .instagram {
        top: 90%
    }
}
@media screen and (min-width: 1440px) and (max-width: 2561px) {
    .article {
        width: 80%; 
        height: 84vh;
        overflow-y: hidden;
    }  
}